.service-wrap {
    display: flex;
    flex-flow: column nowrap;
    @include mq(tablets) {
        flex-flow: row nowrap;
        justify-content: space-between;
    }
}

.service_text {
    color: black;
    padding: 0.5em;
    @include mq(tablets) {
        padding-right: 4em;
    }

    @include mq(50em) {
        width: 50em;
    }
    @include mq($max-width) {
        max-width: 70%;
    }
}

.service_text-paragraph {
    font-size: 1.1em;
}

.service-img_01 {
    background: $color-black url(/images/hp-service_01.jpg);
}

.service-img_02{
    background: $color-black url(/images/hp-service_02.jpg);

}

.service-img_03 {
    background: $color-black url(/images/hp-service_03.jpg);
    @include mq(wide_tablets) {
        margin-right: 0;
    }
}

.service-img {
    color: $color-grey-light;
    background-size: cover;
    background-position: center;
    z-index: 999;
    &:hover {
        text-decoration: none;
    }
}

.service_img-heading {
    padding: 1em;
    margin-bottom: 0;
    color: $color-white;
    text-shadow: 0 2px 4px rgba(255,255,255,0.25);
    a:hover {
        text-decoration: none;
    }

}

.service-links {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    @include mq(tablets) {
    }
    div {
        margin-bottom: 0.35em;
    }
    @include mq(50em) {
        width: 28%;
        margin: 0;
        text-align: left;
    }
}

.service_links-img {
    width: 100%;
    margin-bottom: 0.5em;
    background-size: cover;
    background-position: center;
}

.service_links-info {
    margin-top: 0.5em;
    padding: 1.5em;
    border: 1px solid $color-blue;
    .service_links-heading {
        color: $color-blue;
        font-weight: 300;
        text-align: left;
    }
    hr {
        border: 1px solid $color-blue;
    }
    color: $color-black;
}

h3.service_guarantee-heading {
    margin-bottom: 0.25em;
}