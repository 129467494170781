.content-wrap-contact {
    max-width: 40em;
    margin-bottom: 1em;
    padding: 2em 0;
}

.contact-form {
    padding: 2em;
    margin: 0 auto;
    border: 1px solid $color-blue-transparent;
    border-radius: $border-radius;
}

.form-contact-input {
    padding: 0.5em 0;
    textarea, input {
        width: 100%;
        padding: 0.25em 0.5em;
        border: 1px solid $color-grey-light;
        border-radius: $border-radius;
    }
}

.contact-info-text {
    text-align: center;
    margin: 0 auto 2em;
    @include mq(tablets) {
        padding: 0;
    }
}

.contact-info_heading {
    margin-bottom: 0.5em;
    padding-bottom: 0.25em;
    text-align: center;
    border-bottom: 1px solid $color-blue;
}

.banana {
    opacity: 0;
}