.img_bar-wrap-hp {
    background: $color-black url(/images/img_bars/img_bar-cabinet-400.jpg) no-repeat;

    @include mq(25em) {
        background-image: url(/images/img_bars/img_bar-cabinet-800.jpg);
    }

    @include mq(50em) {
        background-image: url(/images/img_bars/img_bar-cabinet-1200.jpg);
    }

    @include mq($max-width) {
        background-attachment: fixed;
    }

    @include mq(75em) {
        background-image: url(/images/img_bars/img_bar-cabinet.jpg);
    }
}

.img_bar-wrap-about {
    background: $color-black url(/images/img_bars/img_bar-table-400.jpg) no-repeat;
    @include mq(25em) {
        background-image: url(/images/img_bars/img_bar-table-800.jpg);
    }
    @include mq(50em) {
        background-image: url(/images/img_bars/img_bar-table-1200.jpg);
    }
    @include mq(75em) {
        background-image: url(/images/img_bars/img_bar-table.jpg);
    }
}

.img_bar-wrap {
    background-size: cover;
    background-position: center;
    color: $color-grey-light;
    h1, h2, h3, h4, h5, h6 {
        color: $color-grey-light;
    }
}

.img_bar-inner {
    text-align: center;
    padding: 5em 1em;
    max-width: 40em;
    @include mq(tablets) {
        padding: 10em 1em;
    }

    @include mq(68em) {
        padding: 10em 0;
    }
}

.img_bar-heading {

    margin: 0 auto;
    margin-bottom: 2em;
    font-weight: 200;
    @include mq(tablets) {
        font-size: 2em;
        max-width: 40em;
    }
}