.footer {
    max-width: 100%;
    color: $color-grey-light;

    a {
        text-decoration: none;
        color: rgb(255, 255, 255);
        &:hover {
            text-decoration: underline;
        }
    }
}

.footer-heading {
    margin-bottom: 0;
    padding-bottom: 0.5em;
    font-weight: 700;
    color: $color-grey-light;
    border-bottom: 1px solid $color-grey-light;
}

.footer-info {

    background: $color-blue url(/images/footer/footer-400.jpg) no-repeat;
    background-size: cover;
    background-position: center;

    @include mq(25em) {
        background-image: url(/images/footer/footer-800.jpg);
    }



    @include mq(50em) {
        background-image: url(/images/footer/footer-1200.jpg);
    }

    @include mq(75em) {
        background-image: url(/images/footer/footer.jpg);
    }
}

.footer-info_wrap {
    height: 100%;
    padding: 2em 0 1em;
    line-height: 2;
    background-color: rgba(0,0,0,0.45);
    text-shadow: 0 2px 4px rgba(0,0,0,1);
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }
    @include mq(wide_handhelds) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
    }
    @include mq(tablets) {
        padding: 5em 0 6em;
    }
}
.footer-info_column {
    padding-bottom: 0;
    width: 80%;

    hr {
        max-width: 50%;
        margin: 0;
        @include mq(tablets) {
            max-width: inherit;
            margin: auto;
        }
    }
    @include mq(wide_handhelds) {
        margin-right: 2rem;
    }
    @include mq(wide_tablets) {
        float: left;
        margin-bottom: 2em;
    }
}

.footer_logo {
    line-height: 1.5;
    @include mq(wide_handhelds) {
        width: 40%;
    }
    @include mq(wide_tablets) {
        width: 30%;
    }
}

.footer_nav {
    @include mq(wide_handhelds) {
        width: 45%;
    }
    @include mq(wide_tablets) {
        width: 10%;
    }
}

.footer-nav_item-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
}

.footer_info {
    @include mq(wide_handhelds) {
        width: 40%;
    }
    @include mq(wide_tablets) {
        width: 20%;
    }
}

.footer_projects {
    @include mq(wide_handhelds) {
        width: 40%;
    }
    @include mq(wide_tablets) {
        width: 20%;
    }
}

.footer_icon {
    max-width: 10%;
    padding-right: 0.5em;
}

.copyright {
    text-align: center;
    padding: 1em 0;
    background-color: darken($color-blue, 10%);
    color: $color-white;
    a { color: $color-white; }
}
.footer_info li, .footer_nav li{
    font-style: italic;
    //font-size: 0.8em;
}