.header-wrap {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    background-color: rgba(255,255,255, 0.9);
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.25);
}
.header {
    padding: 1em 0.5em;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    @include mq(50em) {
      align-items: center;
      max-width: $max-width;
    }
    @include mq($max-width) {
        padding: 1em 0;
    }
}

.header-logo_wrap {
    margin: 0;
    flex-basis: 80%;
    width: 80%;
    max-width: 20em;
    padding-right: 1em;
    transition: all 0.25s ease-out;
    @include mq(50em) {
      flex-basis: 60%;
      width: 60%;
    }
    img {
      transition: all 0.25s ease-out;
    }
}

.header-logo_small {
    transition: all 0.25s ease;
    padding: 0.25em;
    .js-header-nav_item-menu {
      top: 1em;
      transition: 0.1s ease-in;
    }
    .header-logo_wrap img {
      transition: all 0.25s ease-out;
      max-width: 15em;
    }
}

.header-nav {
    float: left;
    transition: 1s;
    padding-left: 0;
    margin: 0 0 0 auto;
    margin-top: 1.5em;
    text-align: right;
    @include mq(50em) {
      padding-left: 1em;
      margin-top: 0;
      display: table;
    }
}

.header-nav-wrap {
    width: 100%;
    padding: 0.75em 1em;
     @include mq($max-width) {
        padding: 0.75em 0;
    }
}

.header-nav_item {
    display: none;
    padding-bottom: 0.5em;
    @include mq(50em) {
        display: inline-block;
        font-weight: 400;
        display: inline;
        margin-right: 2em;
        padding-bottom: 0;
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.header-nav_item a {
    color: $color-blue;
    text-decoration: none;
}

.header-nav_item-menu {
    display: inline;
    position: absolute;
    right: 1em;
    top: 2.3em;
    transition: 0.35s ease-in;
    @include mq(50em) {
        display: none;
  }
}

.header-mobile_open {
    margin-bottom: 0;
    width: 100%;
    .header-nav {
        margin-top: 4rem;
    }
    .header-nav_item {
        display: block;
        width: 100%;
        &-menu {
            padding-left: 1em;
            display: inline-block;
            width: 2.5em;
        }
    }
}

.header-nav_item-home {
  margin-top: 2em;
}