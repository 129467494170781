h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
    font-weight: 300;
    color: $color-blue;
}

h1 {
    font-size: 3em;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.5em;
    line-height: 1.5;
}

h4 {
    font-size: 1.25em;
}

h5 {
    font-size: 0.9em;
}