.herobar {
    color: $color-white;
    padding: 7em 1em 4em;
    text-shadow: 0 4px 8px rgba(0,0,0,0.5);

    h1, h2, h3, h4, h5, h6 {
        color: $color-white;
    }

    @include mq(wide_handhelds) {
        padding: 9em 1em 4em;
    }

    @include mq(tablets) {
         padding: 12em 1em 10em;
    }
    @include mq(wide_tablets) {
         padding: 12em 0 10em;
    }
}

.herobar-hp {
    display: flex;
    align-items: center;
    background: $color-blue url(/images/herobars/herobar-hp-400.jpg) no-repeat;
    padding: 0;

    .herobar-content {
        max-width: 40rem;
        margin: 0;
        text-align: left;
    }

    .herobar-text {
        margin: 0;
    }

    @include mq(25em) {
        background-image: url(/images/herobars/herobar-hp-800.jpg);
    }

    @include mq(tablets) {
       padding: 0;
    }

    @include mq(50em) {
        background-image: url(/images/herobars/herobar-hp-1200.jpg);
    }

     @include mq(75em) {
        background-image: url(/images/herobars/herobar-hp.jpg);
    }
}

.herobar-about {
    background: $color-black url(/images/herobars/herobar-about-400.jpg) no-repeat;
    @include mq(25em) {
        background-image: url(/images/herobars/herobar-about-800.jpg);
    }

    @include mq(50em) {
        background-image: url(/images/herobars/herobar-about-1200.jpg);
    }

    @include mq(75em) {
        background-image: url(/images/herobars/herobar-about.jpg);
    }
}

.herobar-contact {
    background: $color-black url(/images/herobars/herobar-contact-400.jpg) no-repeat;
    @include mq(25em) {
        background-image: url(/images/herobars/herobar-contact-800.jpg);
    }

    @include mq(50em) {
        background-image: url(/images/herobars/herobar-contact-1200.jpg);
    }

    @include mq(75em) {
        background-image: url(/images/herobars/herobar-contact.jpg);
    }
}

.herobar-residential {
    background: $color-black url(/images/herobars/herobar-gallery_residential-400.jpg) no-repeat;
    @include mq(25em) {
        background-image: url(/images/herobars/herobar-gallery_residential-800.jpg);
    }

    @include mq(50em) {
        background-image: url(/images/herobars/herobar-gallery_residential-1200.jpg);
    }

    @include mq(75em) {
        background-image: url(/images/herobars/herobar-gallery_residential.jpg);
    }
}

.herobar-commercial {
   background: $color-black url(/images/herobars/herobar-gallery_commercial-400.jpg) no-repeat;
    @include mq(25em) {
        background-image: url(/images/herobars/herobar-gallery_commercial-800.jpg);
    }

    @include mq(50em) {
        background-image: url(/images/herobars/herobar-gallery_commercial-1200.jpg);
    }

    @include mq(75em) {
        background-image: url(/images/herobars/herobar-gallery_commercial.jpg);
    }
}

.herobar-testimonials {
    background: $color-black url(/images/herobar-testimonials.jpg) no-repeat;
}

.herobar {
    background-size: cover;
    background-position: center;
}

.herobar-content {
    margin: 0 auto;
    text-align: center;
}

.herobar-text {
    font-weight: 300;
    max-width: 30em;
    margin: 0 auto;
    @include mq(tablets) {
        font-size: 1.5em;
    }
}

.herobar-heading, h1.herobar-heading:first-of-type {
    font-size: 2em;
    margin-top: 0.5em;
    font-weight: 400;
    @include mq(tablets) {
        font-size: 3em;
    }
}

.herobar-no_hero {
    padding: 10em 0 2em;
    h1 { color: $color-blue; }
}

.herobar-inner {
    padding: 7rem 0 4rem;
    background-color: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    @include mq(tablets) {
        padding: 12em 0 10em;
    }

}