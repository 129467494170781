.hp-services_wrap {
    width: 100%;
    border-top: 20px solid $color-blue;
    @include mq(wide_tablets) {
         margin-bottom: -11em;
    }
}
.hp-service_inner {
    @include mq(tablets) {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
    }

    @include mq(wide_tablets) {
         margin-top: -8em;
    }
    a:hover {
        text-decoration: none;
    }
}

.hp-service {
    position: relative;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 16em;
    margin: 0.5em 0;
    color: $color-white;
    text-decoration: none;
    border: 1px solid $color-grey-light;
    text-align: center;
    transition: all 0.25s ease;
    @include mq(wide_tablets) {
        width: 32%;
        flex-basis: 32%;
        max-width: 22em;
    }

    h2 {
        font-size: 1.5em;
        max-width: 12em;
        margin-bottom: 0;
        padding: 0.25em 0;
        font-weight: 200;
        color: $color-white;
        text-shadow: 0 2px 4px rgba(255,255,255,0.25);
        @include mq(tablets) {
            font-size: 2em
        }
    }

    &:hover {
        cursor: pointer;
        border: 1px solid darken($color-grey-light, 25%);
        h2 {
            // border-bottom: 2px solid $color-grey-light;
        }
    }
}

.box-blue {
    background: darken($color-blue, 25%) url(/images/hp/hp-about-400.jpg) no-repeat;
    background-size: cover;
    background-position: left;
    padding: 3em 0;

    .content-wrap {
        padding: 1em 0 1em 0;
    }

    @include mq(25em) {
        background-image: url(/images/hp/hp-about-800.jpg);
    }

    @include mq(50em) {
        background-image: url(/images/hp/hp-about-1200.jpg);
    }

    @include mq(wide_tablets) {
        padding: 14em 0 4em;
    }

    @include mq(75em) {
        background-image: url(/images/hp/hp-about-1200.jpg);
    }
}

.box-blue-border_inner {
    background-color: rgba(0,37,71,0.7);
    max-width: 100%;
    padding: 2em;
}

.box-blue-content {
    border: 2px solid #000;
    padding: 2em 2em 2em 2em;
    color: $color-grey-light;
    text-shadow: 0 2px 4px rgba(255,255,255,0.2);
    @include mq(tablets) {
        padding: 3em 4em 3em 4em;
    }
}

.box-blue-header {
    font-size: 1.75em;
    text-align: center;
    font-weight: 200;
    color: $color-grey-light;
}

.box-blue-paragraph {
    p {
        font-size: 1.25em;
        text-align: center;
    }
}

.hp_bottom-wrap {
    border-bottom: 1px solid rgba($color-blue, 0.5);
    @include mq(tablets) {
        display: flex;
        flex-flow: row wrap;
        padding: 6em 0;
    }
}

.hp_bottom-left {
    color: $color_blue;
    flex-basis: 40%;
    padding-top: 2em;
    .hp_bottom-paragraph {
        font-size: 1.3em;
    }
    @include mq(tablets) {
        padding-top: 4em;
    }
}

.hp_bottom-right {
    margin-top: 0;
    flex-basis: 60%;
    @include mq(tablets) {
        width: 60%;
    }
}

.hp-project_wrap {
    display: flex;
    justify-content: center;
}