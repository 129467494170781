button, .button {
	border: 3.5px solid $color-blue;
	padding: 0.65em 6em 0.65em 6em;
	color: $color-white;
	background-color: $color-blue;
	border-radius: $border-radius;
	&:hover {
		text-decoration: none;
		background-color: lighten($color-blue, 10%);
		color: $color-white;
	}
}

.button-contact {
	margin-left: 0.5em;
}

.testimonial-button {
	display: block;
	margin: auto;
	margin-top: 3em;
}

.button-img_bar {
    font-size: 1.25em;
    margin: 0 auto;
    padding: 0.35em 2em;
    border: 2px solid $color-white;
    border-radius: 25px;
    transition: all ease 0.8s;
	background: transparent;

    @include mq(tablets) {
        max-width: 25%;
    }

    &:hover {
        background-color: rgba($color-blue, 0.5);
    }
}