.content-wrap {
    @include clearfix;
    max-width: $max-width;
   	width: 100%;
    margin: 0 auto;
    padding: 1em;
    @include mq(68em) {
    	padding: 1em 0;
	}
    &-flex {
        display: flex;
        flex-wrap: wrap;
    }
}

.-text-grey {
	color: $color-grey-light;
}