.gallery-content_wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 2.5em auto;
}

.gallery-wrap {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        margin-right: 1em;
        @include mq(50em) {
            &:nth-of-type(4n) {
                margin-right: 0;
            }
        }
    }

    h1, h1:first-child {
        margin-top: 1em;
        margin-bottom: 0.5em;
    }
}

.gallery-left_col {
    p:first-of-type {
        margin-top: 0;
    }
    @include mq(50em) {
        width: 50em;
    }
    @include mq($max-width) {
        max-width: 69%;
        margin-right: 1em;
    }
}

.gal-img, .pop_up-close, .pop_up-next {
    cursor: pointer;
}

.img-wrap img {
    min-height: 180px;
    min-width: 180px;
}

// Pop up on gallery image click.
.pop_up {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: -1em;
    width: 100%;
    height: 110%;
    overflow: auto;
    background-color: darken(rgba(0,0,0,0.3),10%);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animate-opacity;
    animation-duration: 0.75s;
}

@keyframes animate-opacity {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

.pop_up-content {
    width: 100%;
    height: 100%;
    z-index: 100001;
}

.img-modal {
    position: relative;
    top: 3.5em;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    img {
        background-color: rgba(0,0,0,0.7);
        padding: 0.15em;
        text-align: center;
    }
}

.modal_img-wrap {
    max-width: 70%;
}

.display {
    display: block;
}

.pop_up-next, .pop_up-prev {
    font-size: 3rem;
    line-height: 1;
    z-index: 100002;
    a {
        color: $color-white;
        text-decoration: none;
    }
}

.gallery-nav-text {
    font-size: 1.25rem;
}

.travelers {
    height:38px;
    width: 187px;
}