.virtual_tours-wrap-wrap {
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid $color-blue;
}
.virtual_tours-wrap {
    padding: 0;
    list-style: none;
}

.virtual_tour-wrap {
    @include mq(tablets) {
        flex-basis: 48%;
        width: 48%;
        max-width: 48%;
        margin-right: 1em;
    }
    &:last-of-type {
        margin-right: 0;
    }
}

.virtual_tour-address {
    font-size: 1.25em;
    margin: 0.25em 0;
}

.virtual_tour-link {
    &:hover {
        text-decoration: none;
    }
}